import { render, staticRenderFns } from "./selecaoInicialModelos.vue?vue&type=template&id=6560b907&scoped=true&"
import script from "./selecaoInicialModelos.vue?vue&type=script&lang=js&"
export * from "./selecaoInicialModelos.vue?vue&type=script&lang=js&"
import style0 from "./selecaoInicialModelos.vue?vue&type=style&index=0&id=6560b907&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6560b907",
  null
  
)

export default component.exports