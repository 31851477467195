<template>
  <div class="produtos">
    <span class="tipo-pessoa-box">
      <span class="material-icons icone">{{ iconeTipoPessoa }}</span>
      {{ opcaoInicialSelecionada }}
    </span>

    <div class="linha1">
      <div class="disclaimer">Selecione o modelo que deseja consultar</div>
    </div>
    <div class="down">
      <span class="searchBox">
        <button
          class="icon-button"
          id="search-button"
          @click="liberaPesquisa = !liberaPesquisa"
        >
          <span class="material-symbols-outlined icon-search">search</span>
        </button>
        <b-tooltip
          :target="'search-button'"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          Pesquisar por palavras-chave
        </b-tooltip>
        <md-field
          v-if="liberaPesquisa"
          class="searchInput md-layout-item md-size-45 md-medium-size-50 md-xsmall-size-100"
        >
          <label for="pesquisaProdutos"> Pesquisar </label>
          <md-input id="pesquisaProdutos" v-model="pesquisaProd"></md-input>
        </md-field>
      </span>
      <div class="footer">
        <md-card-actions class="btn-right">
          <md-button
            data-html2canvas-ignore
            native-type="submit"
            class="md-primary"
            @click="passoAnterior()"
            >Anterior
          </md-button>
          <md-button
            data-html2canvas-ignore
            native-type="submit"
            class="md-primary"
            @click="proxPasso()"
            :disabled="modeloSelecionado.Valor == undefined"
            >Próxima
          </md-button>
        </md-card-actions>
      </div>
    </div>
    <div class="cards" v-if="consultasAdicionaisPesquisadas.length > 0">
      <div class="cardBox consultas">
        <div class="header consultas">
          <div class="headerTitle">
            <img :src="iconeConsultasAdd" alt="" class="icone-produtos" />
            <div class="nome consultas">
              <div v-html="highlight(titleConsultasAdd)"></div>
            </div>
            <div class="aviso text-desc">
              {{ descConsultasAdd }}

              <!-- <div class="seleciona_todos">
                Selecionar todas -->
              <div class="checkbox" :class="checkboxClass">
                <div>
                  <input
                    class="input"
                    :checked="checkTodasConsultasAdd"
                    type="checkbox"
                    @change="selecionaTodasConsultas()"
                  />
                </div>
              </div>
              <!-- </div> -->
              <!-- <checkCustom @selected="selecionaTodasConsultas($event)" /> -->
            </div>
            <img
              src="/img/selecao-produtos/Seta-cinza.png"
              class="arrow-icon cinza"
              :class="{ aberta: abreConsultasAdicionais }"
              @click="abreConsultasAdicionais = !abreConsultasAdicionais"
            />
          </div>
        </div>

        <div
          class="dropdown"
          :class="{ 'dropdown--open': abreConsultasAdicionais }"
          v-if="abreConsultasAdicionais"
        >
          <CardModeloLista
            v-for="modelo in consultasAdicionaisPesquisadas"
            :key="modelo.Valor"
            :modelo="modelo"
            :modeloSelecionado="modeloSelecionado"
            :consultasSelecionadas="consultasSelecionadas"
            :modelosPreferidos="modelosPreferidos"
            :search="pesquisaProd"
            :desabilitaAlerta="desabilitaAlerta"
            @selecionaModelo="selecionaConsultas"
          />
        </div>
      </div>
    </div>
    <div class="cards" v-if="modelosPesquisados.length > 0">
      <div class="cardBox consultas">
        <div class="header consultas">
          <div class="headerTitle">
            <img :src="iconeConsultas" alt="" class="icone-produtos" />
            <div class="nome consultas">
              <div v-html="highlight(titleConsultas)"></div>
            </div>
            <img
              src="/img/selecao-produtos/Seta-cinza.png"
              class="arrow-icon cinza"
              :class="{ aberta: abreConsultasModelos }"
              @click="abreConsultasModelos = !abreConsultasModelos"
            />
          </div>
          <div class="text-desc">{{ descConsultas }}</div>
        </div>

        <div
          class="dropdown"
          :class="{ 'dropdown--open': abreConsultasModelos }"
          v-if="abreConsultasModelos"
        >
          <CardModeloLista
            v-for="modelo in modelosPesquisados"
            :key="modelo.Valor"
            :modelo="modelo"
            :modeloSelecionado="modeloSelecionado"
            :search="pesquisaProd"
            :modelosPreferidos="modelosPreferidos"
            :desabilitaAlerta="desabilitaAlerta"
            @selecionaModelo="selecionaModelo"
            @trocarFavorito="trocarFavorito"
          />
        </div>
      </div>
    </div>
    <div
      class="nao_encontrado"
      v-if="modelosPesquisados.length == 0 && consultasAdicionaisPesquisadas.length == 0"
    >
      <span class="material-icons-outlined sad">sentiment_dissatisfied</span>
      Desculpe, não há produtos com o filtro buscado
    </div>
  </div>
</template>

<script>
import CardModeloLista from "@/components/Modelos/CardModeloLista";

import { userPreferenceStore } from "@/stores/userPreference";
import { mapWritableState, mapActions } from "pinia";
import produtosSuperBoxJson from "@/helpers/produtos.json";

export default {
  name: "selecaoProdutosModelos",
  props: {
    produtos: Array,
    modelos: Array,
    opcaoInicialSelecionada: String,
    modeloSelecionado: Object,
    consultasSelecionadas: Array,
  },
  components: {
    CardModeloLista,
  },

  data() {
    return {
      search: "",
      titleConsultas: "Modelos Outbox",
      iconeConsultas: "/img/selecao-produtos/Icone-consulta-modelos.png",
      descConsultas: "Lista de modelos disponíveis.",
      titleConsultasAdd: "Consultas adicionais",
      iconeConsultasAdd: "/img/selecao-produtos/Icone-consulta-adc.png",
      // descConsultasAdd: "Lista de produtos complementares disponíveis.",
      abreConsultasModelos: true,
      abreConsultasAdicionais: false,

      checkTodasConsultasAdd: false,

      pesquisaProd: "",
      liberaPesquisa: false,
      abreDetalhes: false,

      desabilitaAlerta: false,
    };
  },

  watch: {
    consultasSelecionadas() {
      if (this.consultasSelecionadas.length > 0) {
        this.checkTodasConsultasAdd = true;
      } else {
        this.checkTodasConsultasAdd = false;
      }
      // const todosSelecionados = this.consultasAdicionaisPesquisadas.every(
      //   (consulta) =>
      //     this.consultasSelecionadas.some(
      //       (selecao) => JSON.stringify(consulta) === JSON.stringify(selecao)
      //     )
      // );

      // this.checkTodasConsultasAdd = todosSelecionados;
    },
    // consultasSelecionadas: {
    //   handler() {
    //     const ativaAlerta = this.liberaBlocoAlerta();
    //     if (ativaAlerta) {
    //       this.desabilitaAlerta = false;
    //       const indexAlerta = this.consultasSelecionadas.findIndex(
    //         (item) => item.Nome == "Alertas Gerais"
    //       );
    //       if (indexAlerta === -1) {
    //         this.consultasAdicionais.forEach((produto) => {
    //           if (produto.Nome == "Alertas Gerais") {
    //             this.selecionaConsultas(produto);
    //           }
    //         });
    //       }
    //     } else {
    //       this.desabilitaAlerta = true;
    //       const indexAlerta = this.consultasSelecionadas.findIndex(
    //         (item) => item.Nome == "Alertas Gerais"
    //       );
    //       if (indexAlerta != -1) {
    //         this.consultasAdicionais.forEach((produto) => {
    //           if (produto.Nome == "Alertas Gerais") {
    //             this.selecionaConsultas(produto);
    //           }
    //         });
    //       }
    //     }
    //   },
    // },
    // const ativaAlerta = this.liberaBlocoAlerta();
    // if (ativaAlerta) {
    //   this.consultasAdicionais.some((consulta) => {
    //     if (consulta.Nome == "Alertas Gerais") {
    //       this.selecionaConsultas(consulta);
    //     }
    //   });
    // }
  },

  methods: {
    selecionaTodasConsultas() {
      this.$emit(
        "selecionaTodasConsultas",
        this.checkTodasConsultasAdd,
        this.consultasAdicionais
      );
      if (this.checkTodasConsultasAdd) {
        this.checkTodasConsultasAdd = false;
      } else {
        this.checkTodasConsultasAdd = true;
      }
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferencias",
      "createPreferencia",
      "substituiPreferencia",
      "syncPreferenciaGeral",
    ]),

    selecionaModelo(modelo) {
      this.$emit("selecionaModelo", modelo);
    },

    selecionaConsultas(consulta) {
      this.$emit("selecionaConsultas", consulta);
    },

    proxPasso() {
      this.$router.push("/consultas/modelos/2");
    },

    passoAnterior() {
      this.$router.push("/consultas/modelos/0");
    },

    highlight(texto) {
      if (!this.pesquisaProd) {
        return texto;
      }

      return texto.replace(new RegExp(this.pesquisaProd, "gi"), (match) => {
        return '<span class="highlightText">' + match + "</span>";
      });
    },

    trocarFavorito(modelo) {
      if (this.modelosPreferidos.includes(modelo.Valor)) {
        this.modelosPreferidos = this.modelosPreferidos.filter(
          (produtoPref) => modelo.Valor != produtoPref
        );
      } else {
        this.modelosPreferidos.push(modelo.Valor);
        modelo.Favorito = true;
      }

      this.substituiPreferencia(
        "PortalOutbox_modelosFavoritos",
        this.modelosPreferidos.join(";")
      );

      this.modelosPreferidos.sort();
    },

    liberaBlocoAlerta() {
      return (
        this.consultasSelecionadas.some(
          (consulta) => consulta.Nome == "Informações Cadastrais"
        ) &&
        this.consultasSelecionadas.some(
          (consulta) => consulta.Nome == "Detalhamentos Cadastrais da Matriz"
        )
      );
    },
  },

  async mounted() {

    if (!this.opcaoInicialSelecionada) {
      window.location.href = "/consultas/modelos/0";
    }
    const preferenciasFavoritos = await this.syncPreferenciaGeral(
      "PortalOutbox_modelosFavoritos"
    );

    this.modelosPreferidos = [];
    this.modelosConsultasAddFavoritos = [];

    if (preferenciasFavoritos === undefined) {
      await this.createPreferencia("PortalOutbox_modelosFavoritos");
    } else {
      const valor = preferenciasFavoritos.Valor;
      if (valor != "") {
        this.modelosPreferidos = valor.split(";");
        this.modelosPreferidos.sort();
      }
    }

    const preferenciasFavoritosAdicionais = await this.syncPreferenciaGeral(
      "PortalOutbox_consultaAdicionaisModelos"
    );
    if (preferenciasFavoritosAdicionais === undefined) {
      const preferenciaNome = "PortalOutbox_consultaAdicionaisModelos";

      var preferenciaString =
        "Dados Cadastrais;Mapa Geral de Riscos;Informações Cadastrais;Detalhamentos Cadastrais da Matriz";

      await this.createPreferencia(preferenciaNome);
      await this.substituiPreferencia(preferenciaNome, preferenciaString);

      if (Object.keys(this.modeloSelecionado).length == 0) {
        this.consultasAdicionais.forEach((produto) => {
          if (produto.Nome == preferenciaNome) {
            this.selecionaConsultas(produto);
          }
        });
      }
    } else {
      const valorGet = preferenciasFavoritosAdicionais.Valor;
      var objetos = valorGet.split(";");
      this.modelosConsultasAddFavoritos = objetos;
      this.modelosConsultasAddFavoritos.sort();

      //preferencias disponíveis para pf
      if (this.opcaoInicialSelecionada == "Pessoa Física") {
        objetos = objetos.filter(
          (item) => item == "Dados Cadastrais" || item == "Mapa Geral de Riscos"
        );
      }
      //preferencias disponíveis para pj
      if (this.opcaoInicialSelecionada == "Pessoa Jurídica") {
        objetos = objetos.filter(
          (item) =>
            item == "Informações Cadastrais" ||
            item == "Detalhamentos Cadastrais da Matriz" ||
            item == "KYC & Due Diligence"
        );
      }

      const index = this.consultasSelecionadas.findIndex((item) =>
        objetos.includes(item.Nome)
      );

      if (index === -1) {
        if (Object.keys(this.modeloSelecionado).length == 0) {
          this.consultasAdicionais.forEach((produto) => {
            if (objetos.includes(produto.Nome)) {
              this.selecionaConsultas(produto);
            }
          });
        }
      }
    }

    const ativaAlerta = this.liberaBlocoAlerta();
    if (ativaAlerta) {
      this.consultasAdicionais.some((consulta) => {
        if (consulta.Nome == "Alertas Gerais") {
          this.selecionaConsultas(consulta);
        }
      });
    }
  },

  computed: {
    descConsultasAdd() {
      if (this.consultasSelecionadas.length === 0) {
        return "Nenhuma consulta selecionada";
      }

      const todosSelecionados = this.consultasAdicionaisPesquisadas.every((consulta) =>
        this.consultasSelecionadas.some(
          (selecao) => JSON.stringify(consulta) === JSON.stringify(selecao)
        )
      );
      if (todosSelecionados) {
        return "Lista completa selecionada";
      }

      return "Lista parcial selecionada";
    },

    checkboxClass() {
      if (this.consultasSelecionadas.length === 0) {
        return "";
      }

      const todosSelecionados = this.consultasAdicionaisPesquisadas.every((consulta) =>
        this.consultasSelecionadas.some(
          (selecao) => JSON.stringify(consulta) === JSON.stringify(selecao)
        )
      );

      if (todosSelecionados) {
        return "checkbox-azul";
      }

      return "checkbox-verde";
    },
    ...mapWritableState(userPreferenceStore, {
      modelosPreferidos: "modelosPreferidos",
      modelosConsultasAddFavoritos: "modelosConsultasAddFavoritos",
    }),

    modelosFiltrados() {
      const modelosOrdenados = this.modelos.slice().sort((a, b) => {
        const aIndex = this.modelosPreferidos.indexOf(a.Valor);
        const bIndex = this.modelosPreferidos.indexOf(b.Valor);

        if (aIndex === -1) return 1;
        if (bIndex === -1) return -1;

        return aIndex - bIndex;
      });

      // Filtra os modelos com base na opção inicial selecionada
      if (this.opcaoInicialSelecionada === "Pessoa Física") {
        return modelosOrdenados.filter(
          (modelo) =>
            (modelo.TipoPessoaConsulta === "Física" ||
              modelo.TipoPessoaConsulta === "Indiferente") &&
            modelo.Descricao !== "-"
        );
      } else if (this.opcaoInicialSelecionada === "Pessoa Jurídica") {
        return modelosOrdenados.filter(
          (modelo) =>
            (modelo.TipoPessoaConsulta === "Jurídica" ||
              modelo.TipoPessoaConsulta === "Indiferente") &&
            modelo.Descricao !== "-"
        );
      } else {
        return [];
      }
    },

    modelosPesquisados() {
      if (this.pesquisaProd !== "") {
        const pesquisaMinuscula = this.pesquisaProd.toLowerCase();

        const objetosFiltrados = this.modelosFiltrados.filter((objeto) => {
          const valorContem = objeto.Valor.toLowerCase().includes(pesquisaMinuscula);
          const descricaoContem = objeto.Descricao.toLowerCase().includes(
            pesquisaMinuscula
          );

          return valorContem || descricaoContem;
        });

        return objetosFiltrados;
      } else {
        return this.modelosFiltrados;
      }
    },

    consultasAdicionais() {
      const arrysuperbox = produtosSuperBoxJson.produtos;

      if (this.opcaoInicialSelecionada == "Pessoa Física") {
        const produtosFiltrados = this.produtos.filter(
          (produto) =>
            produto.Nome === "Dados Cadastrais" || produto.Nome === "Mapa Geral de Riscos"
        );
        return produtosFiltrados.map((consulta) => {
          return {
            ...consulta,
            Valor: consulta.Box,
          };
        });
      } else {
        var produtosFiltrados = this.produtos
          .filter(
            (produto) =>
              produto.Nome === "Informações Cadastrais" ||
              produto.Nome === "Detalhamentos Cadastrais da Matriz"
          )
          .map((consulta) => {
            return {
              ...consulta,
              Valor: consulta.Box,
            };
          });

        var controleProdKYC = this.produtos.filter((produto) =>
          produto.Box.includes("BOXKYC")
        );

        var prodKYC = [];
        if (controleProdKYC.length == 4) {
          prodKYC = this.produtos
            .filter((produto) => produto.Box == "BOXKYC001")
            .map((consulta) => {
              return {
                ...consulta,
                Valor: "BOXKYC",
              };
            });
        }

        var prodAdicional = [];

        if (produtosFiltrados.length > 1) {
          prodAdicional = arrysuperbox
            .filter((item) => item.Box === "BOXSUPPF009")
            .map((item) => {
              return {
                ...item,
                TipoProduto: "PJ",
                Valor: "BOXSUPPJ009",
              };
            });
        }

        return produtosFiltrados.concat(prodKYC, prodAdicional);
      }
    },

    consultasAdicionaisPesquisadas() {
      if (this.pesquisaProd !== "") {
        const pesquisaMinuscula = this.pesquisaProd.toLowerCase();

        const objetosFiltrados = this.consultasAdicionais.filter((objeto) => {
          const valorContem = objeto.Valor.toLowerCase().includes(pesquisaMinuscula);
          const descricaoContem = objeto.Descricao.toLowerCase().includes(
            pesquisaMinuscula
          );

          return valorContem || descricaoContem;
        });

        return objetosFiltrados;
      } else {
        return this.consultasAdicionais;
      }
    },

    iconeTipoPessoa() {
      if (this.opcaoInicialSelecionada == "Pessoa Física") {
        return "face";
      } else if (this.opcaoInicialSelecionada == "Pessoa Jurídica") {
        return "business";
      } else {
        return "";
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.linha1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4356a5;
  border-radius: 20px;
  height: 60px;
  background-image: url(/img/selecao-produtos/barra.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  width: 75%;
  max-width: 900px;
}

.nao_encontrado {
  margin: 30px 0;
  grid-column: 1/4;
  font-size: 1.5rem;
  font-weight: 300;

  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.sad {
  font-size: 3rem;
}
.produtos {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  width: 100%;
}
.tipo-pessoa-box {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  opacity: 0.5;

  position: absolute;
  right: 13%;
  top: 140px;

  @include mediaQuery(large) {
    right: 22%;
  }
  .icone {
    position: absolute;
    top: -65px;
    font-size: 70px;
    opacity: 0.2;
  }
}
.disclaimer {
  padding: 0 20px;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;
  margin: 0 0 5px 0;
}

.searchBox {
  display: flex;
  width: 75%;
  justify-content: flex-start;
  max-width: 900px;
  align-items: center;
  padding: 0 5px;
}

.searchInput {
  margin: 10px 0px 0 40px;
  padding: 5px 0;
  border-radius: 20%;
  max-width: 270px !important;
  min-width: 270px !important;
}

.icon-search {
  font-size: 18px;
  padding: 0;
  height: 18px;
  cursor: pointer;
}
.icon-button {
  margin: 5px 0 0 0;
  padding: 5px;
  border-radius: 5px;
  height: 28px;
  cursor: pointer;
  border: none;
  position: absolute;
  z-index: 2;

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  &:active {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.seleciona_todos {
  display: flex;
  gap: 10px;
  width: 30%;
  color: #68686b;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}

.checkbox {
  display: flex;
  justify-content: center;
  margin: 5px 0 0 5px;
}

input[type="checkbox"] {
  appearance: none;
  background-color: white;
  margin: 0 5px;
  font: inherit;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 0.15em solid #1da0f2;
  transform: translateY(-0.075em);
  cursor: pointer;
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em white;

  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.checkbox-azul {
  input:checked {
    border: 0.15em solid #1da0f2;
    background-color: #1da0f2;
  }
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.checkbox-verde {
  input {
    border: 0.15em solid #10b981;
    background-color: #10b981;
  }
}

.cards {
  width: 75%;
  max-width: 900px;
  margin: 0px 0 10px 0;
  padding: 0 5px;
  gap: 10px;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr;
  height: fit-content;
  z-index: 10;

  &.sem-margem {
    margin: 0px;
  }
}

.cardBox {
  width: 100%;
  border-radius: 20px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  &.consultas {
    border: 1px solid $outbox-medium-blue;
    background-color: white;
  }
}

.header {
  width: 100%;
  // height: 190px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
  margin: 0;
  border-radius: 20px;
  cursor: pointer;
  z-index: 10;

  &.consultas {
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 20px;
    gap: 0px;
  }
}

.header-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;

  &.tit {
    flex: 1;
  }

  &.linha {
    background-color: #4356a5;
    height: 120px;
    width: 2px;

    &.selecionado {
      background-color: white;
    }
  }

  &.selecao {
    align-items: center;
    margin: 0 40px 0 20px;
  }
}

.img-logo-sb {
  height: 150px;
}

.select-icon {
  height: 50px;
  margin: 30px 0 20px 0;
  position: relative;
}

.arrow-icon {
  height: 40px;
  width: 10px;
  transform: rotate(90deg);
  position: relative;

  &.aberta {
    transform: rotate(270deg);
  }
  &.cinza {
    height: 30px;
    bottom: 10px;
  }
}
.icon {
  height: 46px;
  font-size: 4rem;
  margin-right: 20px;
}

.headerTitle {
  width: 100%;
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
}
.nome {
  font-weight: 600;
  font-size: 40px;
  width: 60%;

  &.consultas {
    font-size: 22px;
    width: 100%;
    color: #86878f;
  }
  &.sup {
    line-height: 60px;
  }
}

.text-desc {
  font-size: 14px;
  line-height: 18px;
  text-align: justify;
  color: #68686b;
  margin: 0 0 0px 0;
}

.aviso {
  width: 350px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 30px;
}

.icone-produtos {
  height: 40px;
  margin: 0 10px;
}

.dropdown {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 10px;
  height: 0;
  visibility: hidden;
  transition: height 0.3s ease;
  border-radius: 20px;
  // position: relative;
  // bottom: 30px;

  &--open {
    width: 100%;
    height: fit-content;
    visibility: visible;
    padding-top: 0px;
    max-height: 400px;
    overflow-y: auto;
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 20px;
  // border-top: 0.3px solid var(--accent);
  width: 100%;
  padding: 0 0px;
  background-color: transparent;

  .btn-right {
    gap: 10px;
  }
}

.down {
  width: 75%;
  max-width: 890px;
  display: flex;
  position: relative;
  top: 20px;
}

@media (max-width: 1240px) {
  .linha1 {
    min-width: 500px;
  }
  .disclaimer {
    font-size: 1.5rem;
  }

  .header {
    min-width: 500px;
  }
}
@media (max-width: 600px) {
  .produtos {
    overflow-x: scroll;
    align-items: flex-start;
  }
  .headerTitle {
    justify-content: space-between;
  }
  .searchBox {
    width: 100%;
    min-width: 280px;
  }

  .cards {
    width: auto !important;
  }
  .disclaimer {
    min-width: 500px;
  }

  .tipo-pessoa-box {
    right: 0;
    top: -22px;
  }

  .seleciona_todos {
    width: auto;
  }

  .nome {
    width: auto;
  }
}
.disable {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
