<template>
  <div>
    <ResultadosModelos
      v-if="
        (!ativaTrilhaConsultas && !liberaTelaSuperbox) ||
        (ativaTrilhaConsultas && tipoConsultaSelecionada == 'Modelos')
      "
      :opcao_inicial="opcaoInicialSelecionada"
      :modeloSelecionado="modeloSelecionado"
      :resultadoModelo="resultadoModelo"
      :consultasSelecionadas="consultasSelecionadas"
      :resultadoConsultaAdd="resultadoConsultaAdd"
      :terminouConsulta="terminouConsulta"
      :liberaBlocoAlerta="liberaBlocoAlerta"
      :modelos="modelos"
    />

    <telaSuperBox
      v-if="
        (!ativaTrilhaConsultas && liberaTelaSuperbox) ||
        (liberaTelaSuperbox &&
          ativaTrilhaConsultas &&
          tipoConsultaSelecionada == 'Superbox')
      "
      :opcaoInicialSelecionada="opcaoInicialSelecionada"
      :produtos="produtos"
      :campos="campos"
      :contexto="contexto"
      :liberaTelaSuperbox="liberaTelaSuperbox"
      :liberaConsultaSuperbox="liberaConsultaSuperbox"
    />

    <div
      class="overlay"
      v-if="abreSelecaoConsultaCiclica"
      @click="abreSelecaoConsultaCiclica = false"
    ></div>

    <SelecaoConsultaAdicional
      v-if="abreSelecaoConsultaCiclica"
      :documento="documentoConsultar"
      :tipo="tipoDocumentoConsultar"
    />

    <TrilhaConsultas v-if="!liberaSpinner && consultasTrilha.length > 0" />

    <div class="spinnerBG" v-if="liberaSpinner">
      <Loading class="spinner" />
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { produtosStore } from "@/stores/produtos";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";
// import Loading from "../../../components/Loading.vue";
import telaSuperBox from "@/pages/Superbox/Nova/telaSuperBox.vue";
import ResultadosModelos from "@/components/Modelos/ResultadosModelos.vue";
import TrilhaConsultas from "@/components/Modelos/TrilhaConsultas.vue";
import Loading from "@/components/Loading";
import SelecaoConsultaAdicional from "@/components/Modelos/SelecaoConsultaAdicional.vue";

export default {
  name: "telaResultadosModelos",

  props: {
    opcaoInicialSelecionada: String,
    modeloSelecionado: Object,
    resultadoModelo: Object,
    consultasSelecionadas: Array,
    resultadoConsultaAdd: Object,
    terminouConsulta: Boolean,
    liberaBlocoAlerta: Boolean,
    modelos: Array,
    produtos: Array,
    campos: Array,
    contexto: Array,
    liberaTelaSuperbox: Boolean,
    liberaConsultaSuperbox: Boolean,
  },

  data() {
    return {
      liberaSpinner: false,
    };
  },
  components: {
    Loading,
    ResultadosModelos,
    TrilhaConsultas,
    telaSuperBox,
    SelecaoConsultaAdicional,
    // Loading,
  },

  async mounted() {},

  methods: {
    ...mapActions(userPreferenceStore, [
      "syncPreferenciaOcultarDados",
      "syncPreferenciaConsultasTooltips",
      "syncPreferenciaTipoDownload",
    ]),
  },

  computed: {
    ...mapWritableState(produtosStore, {
      codigo_agrupador: "codigo_agrupador",
      codigo_agrupador_box: "codigo_agrupador_box",
    }),

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      ativaTrilhaConsultas: "ativaTrilhaConsultas",
      tipoConsultaSelecionada: "tipoConsultaSelecionada",
      abreSelecaoConsultaCiclica: "abreSelecaoConsultaCiclica",
      documentoConsultar: "documentoConsultar",
      tipoDocumentoConsultar: "tipoDocumentoConsultar",
      consultasTrilha: "consultasTrilha",
    }),
  },
};
</script>

<style lang="scss" scoped>
.container-mod {
  width: 100%;
  margin: 0px;
}

.spinnerBG {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 125%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.spinner {
  position: fixed;
  top: 40%;
  left: 52%;
  transform: scale(1.1);
  z-index: 10;
}

.overlay {
  position: fixed;
  height: 110vh;
  width: 110vw;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  z-index: 9;
  left: 0;
}
</style>
