<template>
  <div class="inicial-container">
    <p class="disclaimer">Selecione o tipo de consulta de modelo desejado</p>
    <div class="opcoes">
      <div v-for="opcao in opcaoInicial" :key="opcao.Nome">
        <md-tooltip v-if="opcao.Disabled">
          {{ opcao.Nome }} não disponível para este usuário
        </md-tooltip>
        <div
          class="opcao"
          @click="opcao.Disabled ? '' : emiteEvento(opcao.Nome)"
          :class="{
            opcaoInvalida: opcao.Disabled,
            opcaoValida: !opcao.Disabled,
            opcaoSelecionada: verificaSelecionada(opcao.Nome),
          }"
        >
          <span class="material-icons icone">{{ opcao.Icone }}</span>
          <p>{{ opcao.Nome }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";

export default {
  name: "selecaoInicialModelos",

  props: {
    modelos: Array,
    opcaoInicialSelecionada: String,
  },
  data() {
    return {
      opcaoInicial: [
        {
          Nome: "Pessoa Física",
          Tipo: "Física",
          Icone: "face",
          Disabled: true,
        },
        {
          Nome: "Pessoa Jurídica",
          Tipo: "Jurídica",
          Icone: "business",
          Disabled: true,
        },
      ],
      loading: true,
    };
  },
  methods: {
    verificaSelecionada(nome) {
      if (nome == this.opcaoInicialSelecionada) {
        return true;
      }
    },

    emiteEvento(opcao) {

      if (this.opcaoInicialSelecionada && opcao != this.opcaoInicialSelecionada) {
        this.$emit("resetConsultas");
        this.resetConsutaAdd();
        this.resetAlertasPf();
        this.resetAlertasPj();
      }

      this.$emit("selecionaInicial", opcao);

      this.$router.push("/consultas/modelos/1");
    },

    async verificaVisibilidadeInicial() {
      const temIndiferente = this.modelos.some(
        (modelo) => modelo.TipoPessoaConsulta === "Indiferente"
      );

      const todosNull = this.modelos.every(
        (modelo) => modelo.TipoPessoaConsulta === null
      );

      if (temIndiferente) {
        this.opcaoInicial.forEach((opcao) => {
          opcao.Disabled = false;
        });
      } else {
        if (todosNull) {
          this.opcaoInicial.forEach((opcao) => {
            opcao.Disabled = true;
          });
        } else {
          this.opcaoInicial.forEach((opcao) => {
            const temMesmoTipo = this.modelos.some(
              (modelo) => modelo.TipoPessoaConsulta === opcao.Tipo
            );
            opcao.Disabled = !temMesmoTipo;
          });
        }
      }
    },

    ...mapActions(dadosConsultaAddStore, ["resetConsutaAdd"]),
    ...mapActions(storeAlertasSuperBox, ["resetAlertasPf"]),
    ...mapActions(storeAlertasSuperBoxPJ, ["resetAlertasPj"]),
  },

  mounted() {
    this.$emit("resetContextos");

    this.verificaVisibilidadeInicial();
    this.loading = false;
  },
};
</script>

<style scoped>
.inicial-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.inicial-container .disclaimer {
  font-weight: 500;
  font-size: 1.6rem;
  text-align: center;
}
.opcoes {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.opcao {
  font-weight: 400;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 0 30px;
}

.inicial-container .icone {
  font-size: 4rem;
}
.opcaoValida {
  cursor: pointer;
}

.opcaoInvalida {
  cursor: initial;
  opacity: 0.4;
}

.opcaoSelecionada {
  color: var(--accent);
}

@media (min-width: 1025px) {
  .inicial-container .disclaimer {
    font-size: 2.2rem;
  }
  .opcoes {
    flex-direction: row;
  }
  .opcao {
    font-size: 1.8rem;
    flex-direction: column;
  }
  .inicial-container .icone {
    font-size: 5rem;
    transition: 0.2s ease;
  }
  .opcaoValida:hover > .icone {
    transform: translateY(-10px);
    color: var(--accent);
  }
  .opcaoValida:hover {
    color: var(--accent);
  }
}

@media (max-width: 1024px) {
  .animate1 {
    animation: reduz1 0.5s forwards;
  }
  .animate2 {
    animation: reduz2 0.5s forwards;
  }
  .animate3 {
    animation: reduz3 0.5s forwards;
  }
}

@keyframes reduz1 {
  0% {
    transform: translateY(120px) translateX(-70px);
    font-size: 300%;
  }
  100% {
    transform: translateY(-10px) translateX(0);
    font-size: 100%;
  }
}

@keyframes reduz2 {
  0% {
    transform: translateY(220px) translateX(-70px);
    font-size: 300%;
  }
  100% {
    transform: translateY(-10px) translateX(0);
    font-size: 100%;
  }
}

@keyframes reduz3 {
  0% {
    transform: translateY(350px) translateX(-100px);
    font-size: 300%;
  }
  100% {
    transform: translateY(-10px) translateX(0);
    font-size: 100%;
  }
}

@media (min-width: 1024px) {
  .animate1 {
    animation: reduz1-desk 0.7s forwards;
  }
  .animate2 {
    animation: reduz2-desk 0.7s forwards;
  }
  .animate3 {
    animation: reduz3-desk 0.7s forwards;
  }
}

@keyframes reduz1-desk {
  0% {
    transform: translateY(180px) translateX(-100px);
    font-size: 300%;
  }
  100% {
    transform: translateY(0);
    font-size: 100%;
  }
}

@keyframes reduz2-desk {
  0% {
    transform: translateY(180px) translateX(0);
    font-size: 300%;
  }
  100% {
    transform: translateY(0);
    font-size: 100%;
  }
}

@keyframes reduz3-desk {
  0% {
    transform: translateY(180px) translateX(200px);
    font-size: 300%;
  }
  100% {
    transform: translateY(0);
    font-size: 100%;
  }
}
</style>
